import { createSlice } from "@reduxjs/toolkit";
import { getAllEmployees, getAllClients, createClient, createEmployee, deleteUserById, getUserById, editEmployee, editClient } from "../actions/usersThunks";
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    user: null,
    loading: null,
    error: null,
    successMessage: '',
    errorMessage: '',
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        //Traer todos los usuarios (clientes/empleados)
        fetchUsersStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchUsersSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchUsersFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear usuario
        createNewUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewUserSuccess(state, action) {
            state.loading = false;
            state.user = action.payload;
            state.successMessage = 'Usuario creado exitosamente';
            state.errorMessage = '';
        },
        createNewUserFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear usuario';
            state.successMessage = '';
        },
        editCloseMessage(state) {
            state.successMessage = '';
            state.errorMessage = '';
        },
        //Borrar un usuario
        deleteUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteUserSuccess(state, action) {
            state.loading = false;
            state.error = null;

            const useruuid = action.payload;
            const deletedUserIndex = state.data.findIndex(user => user.uuid === useruuid);

            if (deletedUserIndex !== -1) {
                state.data.splice(deletedUserIndex, 1);
                if (state.user && state.user.uuid === useruuid) {
                    state.user = null;
                }
            }
        },
        deleteUserFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer un usuario para edición/detalle
        fetchUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchUserSuccess(state, action) {
            state.loading = false;
            state.user = action.payload;
        },
        fetchUserFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearUser(state) {
            state.user = null;
        },
        //Editar un perfil
        editUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        editUserSuccess(state, action) {
            state.loading = false;
            state.user = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = '';
        },
        editUserFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = '';
        },
    }
});

export const fetchEmployees = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchUsersStart());
    try {
        const users = await getAllEmployees(authToken, ...args);
        dispatch(fetchUsersSuccess(users));
    } catch (error) {
        dispatch(fetchUsersFailure(error.message));
    }
});

export const fetchClients = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchUsersStart());
    try {
        const users = await getAllClients(authToken, ...args);
        dispatch(fetchUsersSuccess(users));
    } catch (error) {
        dispatch(fetchUsersFailure(error.message));
    }
});

export const fetchUserById = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchUserStart());
    try {
        const user = await getUserById(authToken, ...args);
        dispatch(fetchUserSuccess(user));
    } catch (error) {
        dispatch(fetchUserFailure(error.message));
    }
});

export const createUser = withAuthentication((authToken, userType, ...args) => async (dispatch, getState) => {
    dispatch(createNewUserStart());
    try {
        var user = null;
        if (userType === '1') {
            user = await createEmployee(authToken, ...args)
        } else {
            user = await createClient(authToken, ...args)
        }
        dispatch(createNewUserSuccess(user));
    } catch (error) {
        dispatch(createNewUserFailure(error.message));
    }
})

export const deleteUser = withAuthentication((authToken, useruuid, ...args) => async (dispatch, getState) => {
    dispatch(deleteUserStart());
    try {
        await deleteUserById(authToken, ...args)
        dispatch(deleteUserSuccess(useruuid));
    } catch (error) {
        dispatch(deleteUserFailure(error.message));
    }
});

export const editUser = withAuthentication((authToken, useruuid, editedUser) => async (dispatch, getState) => {
    dispatch(editUserStart());
    try {
        var user = null;
        if (editedUser.position) {
            user = await editEmployee(authToken, useruuid, editedUser)
        } else {
            user = await editClient(authToken, useruuid, editedUser)
        }
        dispatch(editUserSuccess(user));
    } catch (error) {
        dispatch(editUserFailure(error.message));
    }
});

export const {
    fetchUsersStart,
    fetchUsersSuccess,
    fetchUsersFailure,
    createNewUserStart,
    createNewUserSuccess,
    createNewUserFailure,
    editCloseMessage,
    deleteUserStart,
    deleteUserSuccess,
    deleteUserFailure,
    fetchUserStart,
    fetchUserSuccess,
    fetchUserFailure,
    clearUser,
    editUserStart,
    editUserSuccess,
    editUserFailure
} = usersSlice.actions;

export const selectUserById = (state) => state.users.user
export const selectUsersLoading = (state) => state.users.loading;
export const selectUsers = (state) => state.users.data;
export const selectUsersError = (state) => state.users.error;
export const selectSuccessMessage = (state) => state.users.successMessage;
export const selectErrorMessage = (state) => state.users.errorMessage;

export default usersSlice.reducer;