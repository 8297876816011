// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import profileReducer from './reducers/profileReducer';
import accessesReducer from './reducers/accessesReducer';
import usersReducer from './reducers/usersReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    accesses: accessesReducer,
    users: usersReducer,
  },
});

export default store;
