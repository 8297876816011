import React from 'react';
import { Container } from 'react-bootstrap';

const Home = () => {
  return (
    <Container className="mt-5">
      <h2>Página en construcción</h2>
      <p>Estamos trabajando para brindarte una mejor experiencia.</p>
    </Container>
  );
};

export default Home;
