import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../redux/actions/authThunks';
import { Container, Modal, Button, Spinner } from 'react-bootstrap';
import "./styles/Login.css"
//import img_logo from "../imgs/logo-saigro.png"
import { setUserLogged } from '../redux/reducers/authReducer';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSpinner(true);
    const response = await dispatch(login(username, password));
    setSpinner(false);
    if (response.status === 200) {
      await dispatch(setUserLogged(response.data.user));
      navigate('/home');
    } else {
      setErrorMessage(response.data.message || "Nombre de usuario o contraseña incorrecto");
      setShowErrorModal(true);
    }
  };

  const handleClose = () => {
    setShowErrorModal(false);
    setErrorMessage("");
  };


  return (
    <Container className='login-container'>
      <form onSubmit={handleSubmit}>
        <div className='img-logo'>
          <a href='http://saigro.com.ar/' target="_blank" rel="noopener noreferrer">
            {/* <img src={img_logo} alt='Logo Saigro' /> */}
          </a>
        </div>
        <div className="form-group">
          <label htmlFor="username" className="pb-2">Nombre de Usuario</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="pb-2">Contraseña</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary" disabled={spinner}>
          {spinner ? <Spinner as="span" animation="border" size="sm" /> : 'Iniciar Sesión'}
        </button>
      </form>

      <Modal show={showErrorModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error de Inicio de Sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Login;
